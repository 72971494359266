<template>
  <div class="container">
    <headerTop
      @handleChangeTime="handleChangeTime"
      :value="value"
      :currentBreadcrumblist="currentBreadcrumblist"
      :show="true"></headerTop>
    <div class="flexSb header">
      <div class="header_box1 flexSb posi">
        <div style="position: relative" class="num_box1 num_box12">
          <div class="flexSb num_box1">
            <div class="num">
              {{
                headerTop.physical_rate.excellent
                  ? headerTop.physical_rate.excellent
                  : "- -"
              }}
              <span>
                {{ headerTop.physical_rate.excellent ? "%" : "" }} 优秀率</span
              >
            </div>
            <div class="num num2">
              {{
                headerTop.physical_rate.pass
                  ? headerTop.physical_rate.pass
                  : "- -"
              }}<span>
                {{ headerTop.physical_rate.pass ? "%" : "" }}合格率</span
              >
            </div>
          </div>
          <div class="num_title">体质健康达标率</div>
        </div>
        <img class="header_img header_img1" src="@/assets/img/174.png" alt="" />
        <div @click="handleTo('/attainment')" class="detail flexSc">
          <div>查看详情</div>

          <img class="img_det" src="@/assets/img/147.png" alt="" />
        </div>
      </div>
      <div class="header_box1 header_box2 flexSb posi">
        <div class="num_box1 num_box12">
          <div class="flexSb num_box1">
            <div class="num">
              {{
                headerTop.attendance.sports
                  ? headerTop.attendance.sports
                  : "- -"
              }}<span>
                {{ headerTop.attendance.sports ? "%" : "" }}体育课出勤</span
              >
            </div>
            <div class="num">
              {{
                headerTop.attendance.big_break
                  ? headerTop.attendance.big_break
                  : "- -"
              }}<span>
                {{ headerTop.attendance.big_break ? "%" : "" }} 大课间出勤</span
              >
            </div>
          </div>
          <div class="num_title">出勤率</div>
        </div>
        <img class="header_img header_img1" src="@/assets/img/176.png" alt="" />

        <el-popconfirm
          popper-class="my-popper"
          confirm-button-text="体育课出勤"
          cancel-button-text="大课间出勤"
          title="请选择要查看的出勤"
          confirm-button-type="plain"
          cancel-button-type="plain"
          @confirm="handleTo('/studentAttendanceRecords')"
          @cancel="handleTo('/studentAttRecordsbreaktime')">
          <div slot="reference" class="detail flexSc">
            <div class="flexSc">
              <div>查看详情</div>
              <img class="img_det" src="@/assets/img/147.png" alt="" />
            </div>
          </div>
        </el-popconfirm>
      </div>
      <div class="header_box1 header_box3 flexSb posi">
        <div class="num_box1">
          <div class="num">
            {{
              headerTop.physical_arts_rate
                ? headerTop.physical_arts_rate + "%"
                : "- -"
            }}<span> </span>
          </div>
          <div class="num_title">体艺2+1达成率</div>
        </div>
        <img class="header_img" src="@/assets/img/175.png" alt="" />
        <div @click="handleTo('/studentSportsTwoAndOne')" class="detail flexSc">
          <div>查看详情</div>

          <img class="img_det" src="@/assets/img/147.png" alt="" />
        </div>
      </div>
      <div class="header_box1 header_box4 flexSb posi">
        <div class="num_box1">
          <div class="num">
            {{
              headerTop.homework_completion_rate
                ? headerTop.homework_completion_rate + "%"
                : "- -"
            }}<span> </span>
          </div>
          <div class="num_title">家庭作业完成率</div>
        </div>
        <img class="header_img" src="@/assets/img/173.png" alt="" />
        <div @click="handleTo('/homeWork')" class="detail flexSc">
          <div>查看详情</div>

          <img class="img_det" src="@/assets/img/147.png" alt="" />
        </div>
      </div>
    </div>
    <div class="box1 flexSb">
      <div class="box1_left">
        <div class="box1_title flexSb">
          <div>学生情况</div>
          <div class="flexSb">
            <div>
              总人数：<a @click="handleTo('/studentlist')"
                >{{ studentInfo.student_num ? studentInfo.student_num : "- -" }}
              </a>
            </div>
            <div style="margin: 0 25px">
              年级数：<a @click="handleTo('/gradelist')">
                {{ studentInfo.grade ? studentInfo.grade : "- -" }}
              </a>
            </div>
            <div>
              班级数：<a @click="handleTo('/classlist')"
                >{{ studentInfo.class_num ? studentInfo.class_num : "- -" }}
              </a>
            </div>
          </div>
        </div>

        <div class="flexSt">
          <div class="echart_box" ref="sex_ratio"></div>
          <div class="echart_box" ref="one_hour_after_class"></div>
          <div class="echart_box" ref="number_service"></div>
          <div class="echart_box" ref="number_of_school_teams"></div>
          <div class="echart_box" ref="number_of_winners"></div>
        </div>
      </div>

      <div class="box1_right">
        <div class="box1_title">综合评价</div>
        <div class="evaluValue">
          <div>
            {{ evaluationInfo.score ? evaluationInfo.score : "- -" }}
            <span>分</span>
          </div>
          <div>{{ evaluationInfo.type ? evaluationInfo.type : "- -" }}</div>
        </div>
        <div class="evaluation" ref="evaluation"></div>
      </div>
    </div>

    <div class="box2">
      <div class="box2_title flexSb">
        <div>体质健康双达标变化趋势</div>
        <div class="more">More+</div>
      </div>
      <div class="flexSb">
        <div class="attendance" ref="health"></div>
        <div class="attendanceStudent" ref="health_student"></div>
      </div>
    </div>

    <div class="box3">
      <div class="box2_title flexSb">
        <div>课后一小时数据趋势</div>
        <div class="more">More+</div>
      </div>
      <div class="box3_list">
        <div class="box3_item">
          <div class="flexSb">
            <div class="attendance" ref="one_hour"></div>
            <div class="attendanceStudent" ref="one_hour_student"></div>
          </div>
        </div>
        <div class="box3_item">
          <div class="flexSb">
            <div class="attendance" ref="during_break_time"></div>
            <div
              class="attendanceStudent"
              ref="during_break_time_student"></div>
          </div>
        </div>
        <div class="box3_item">
          <div class="flexSb">
            <div class="attendance" ref="homework"></div>
            <div class="attendanceStudent" ref="homework_student"></div>
          </div>
        </div>
        <div class="box3_item">
          <div class="flexSb">
            <div class="attendance" ref="homework_knowledge"></div>
            <div
              class="attendanceStudent"
              ref="homework_knowledge_student"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="box3 box3_2">
      <div class="box2_title flexSb">
        <div>体艺2+1达成趋势</div>
        <div class="more">More+</div>
      </div>
      <div class="box3_item">
        <div class="flexSb">
          <div class="attendance2" ref="sports_art"></div>
          <div class="attendanceStudent2" ref="sports_art_student"></div>
        </div>
      </div>
    </div>

    <div class="box2">
      <div class="box2_title flexSb">
        <div>俱乐部情况</div>
        <div class="more">More+</div>
      </div>
      <div class="box3_item4">
        <div class="flexSb" style="align-items: flex-start">
          <div class="attendance3">
            <el-table
              :header-cell-style="{
                fontSize: '16px',
                color: '#333333',
                paddingTop: '8px',
                paddingBottom: '8px',
              }"
              :data="tableData"
              stripe
              style="width: 100%">
              <el-table-column label="排名" align="center" width="120">
                <template slot-scope="scope">
                  <img
                    v-if="scope.row.rank == 1"
                    class="img_a_im"
                    src="../../../assets/img/164.png"
                    alt="" />
                  <img
                    v-if="scope.row.rank == 2"
                    class="img_a_im"
                    src="../../../assets/img/165.png"
                    alt="" />
                  <img
                    v-if="scope.row.rank == 3"
                    class="img_a_im"
                    src="../../../assets/img/166.png"
                    alt="" />

                  <div v-if="scope.row.rank > 3">
                    {{ scope.row.rank }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="name"
                label="社会服务机构名称"
                align="center">
              </el-table-column>
              <el-table-column prop="rate" label="评分" align="center">
              </el-table-column>
              <el-table-column
                prop="number"
                label="报名人数"
                align="center"
                width="120"
                fixed="right">
              </el-table-column>
            </el-table>
            <!-- <div class="flexSe elpag">
              <el-pagination
                background
                layout="prev, pager, next"
                :total="1000"
              >
              </el-pagination>
            </div> -->
          </div>
          <div class="attendanceStudent2" ref="club_student"></div>
        </div>
      </div>
    </div>
    <div class="box4 flexSb">
      <div class="box4_left">
        <div class="box2_title flexSb">
          <div>校队情况</div>
          <div class="more">More+</div>
        </div>
        <div class="attendance3">
          <el-table
            :header-cell-style="{
              fontSize: '16px',
              color: '#333333',
              paddingTop: '8px',
              paddingBottom: '8px',
            }"
            :data="tableData"
            stripe
            style="width: 100%">
            <el-table-column label="排名" align="center" width="120">
              <template slot-scope="scope">
                <img
                  v-if="scope.row.rank == 1"
                  class="img_a_im"
                  src="../../../assets/img/164.png"
                  alt="" />
                <img
                  v-if="scope.row.rank == 2"
                  class="img_a_im"
                  src="../../../assets/img/165.png"
                  alt="" />
                <img
                  v-if="scope.row.rank == 3"
                  class="img_a_im"
                  src="../../../assets/img/166.png"
                  alt="" />

                <div v-if="scope.row.rank > 3">
                  {{ scope.row.rank }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="校队名称" align="center">
            </el-table-column>
            <el-table-column prop="rate" label="类型" align="center">
            </el-table-column>
            <el-table-column
              prop="number"
              label="报名人数"
              align="center"
              width="120">
            </el-table-column>
          </el-table>
          <!-- <div class="flexSe elpag">
            <el-pagination background layout="prev, pager, next" :total="1000">
            </el-pagination>
          </div> -->
        </div>
      </div>
      <div class="box4_right">
        <div class="box2_title flexSb">
          <div>竞赛情况</div>
          <div class="more">More+</div>
        </div>
        <div class="flexSb list">
          <div class="list_item">
            <div class="ball flexSc">
              <img src="../../../assets/img/169.png" alt="" />
            </div>
            <div class="num">- -</div>
            <div class="ball_title">报名人数</div>
          </div>
          <div class="list_item">
            <div class="ball ball2 flexSc">
              <img src="../../../assets/img/168.png" alt="" />
            </div>
            <div class="num">- -</div>
            <div class="ball_title">报名人次</div>
          </div>
          <div class="list_item">
            <div class="ball ball3 flexSc">
              <img src="../../../assets/img/167.png" alt="" />
            </div>
            <div class="num">- -</div>
            <div class="ball_title">学生参与率</div>
          </div>
          <div class="list_item">
            <div class="ball ball4 flexSc">
              <img src="../../../assets/img/171.png" alt="" />
            </div>
            <div class="num">- -</div>
            <div class="ball_title">比赛项目</div>
          </div>
          <div class="list_item">
            <div class="ball ball5 flexSc">
              <img src="../../../assets/img/170.png" alt="" />
            </div>
            <div class="num">- -</div>
            <div class="ball_title">获奖人数</div>
          </div>
          <div class="list_item">
            <div class="ball ball6 flexSc">
              <img src="../../../assets/img/172.png" alt="" />
            </div>
            <div class="num">- -</div>
            <div class="ball_title">获奖率</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
import {
  sex_ratio,
  one_hour_after_class,
  number_service,
  number_of_school_teams,
  number_of_winners,
  evaluation,
  health,
  health_student,
  one_hour,
  one_hour_student,
  during_break_time,
  during_break_time_student,
  homework_student,
  homework,
  homework_knowledge,
  homework_knowledge_student,
  sports_art,
  sports_art_student,
  club_student,
} from "../enums/studentLiteracy";
import headerTop from "../../components/newheaderTop/index.vue";
import {
  studentCoreCompetenciesPhysicalHealth,
  studentInfoCoreCompetencies,
  studentCoreCompetenciesStudentSituation,
  studentCoreCompetenciesOverview,
  studentCoreCompetenciesLessonInfo,
  studentCoreCompetenciesHomeworkInfo,
} from "@/api/evaluation";
export default {
  components: {
    headerTop,
  },
  watch: {
    $route: function (to, from) {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
  },
  data() {
    return {
      currentBreadcrumblist: [
        {
          name: "studentLiteracy",
          pathname: "学生核心素养",
        },
      ],
      evaluationInfo: {
        score: 0,
        type: 0,
      },
      studentInfo: {
        student_num: 0,
        grade: 0,
        class_num: 0,
      },
      options: [],
      value: "",
      tableData: [],
      sex_ratio: sex_ratio,
      one_hour_after_class: one_hour_after_class,
      number_service: number_service,
      number_of_school_teams: number_of_school_teams,
      number_of_winners: number_of_winners,
      evaluation: evaluation,
      health: health,
      health_student: health_student,
      one_hour: one_hour,
      one_hour_student: one_hour_student,
      during_break_time: during_break_time,
      during_break_time_student: during_break_time_student,
      homework_student: homework_student,
      homework: homework,
      homework_knowledge_student: homework_knowledge_student,
      homework_knowledge: homework_knowledge,
      sports_art_student: sports_art_student,
      sports_art: sports_art,
      club_student: club_student,
      startTime: "",
      endTime: "",
      yearName: "",
      headerTop: {
        physical_rate: {
          excellent: 0,
          pass: 0,
        },
        attendance: {
          sports: 0,
          big_break: 0,
        },
        physical_arts_rate: 0,
        homework_completion_rate: 0,
      },
    };
  },
  mounted() {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
    this.$nextTick(() => {
      window.scrollTo(0, 0);
      this.handleInitone_hour();
      this.handleInitone_hour_student();

      this.handleInitsports_art();
      this.handleInitsports_art_student();
      this.handleInitclub_student();
    });
  },
  methods: {
    async handleGetstudentCoreCompetenciesHomeworkInfo(type) {
      let data = {
        start_time: this.startTime,
        end_time: this.endTime,
        homework_type: type,
      };
      await studentCoreCompetenciesHomeworkInfo(data).then((res) => {
        //1 = 家庭作业（体育运动）， 2 = 家庭作业（知识素养）
        if (type == 1) {
          // 饼图
          this.homework_student.title.text = "家庭作业类型占比" + this.yearName;
          let pie_chart = res.data.pie_chart;
          let pie_arr = pie_chart.arr;
          let garde_name = pie_chart.garde_name;
          this.homework_student.series[0].data = [];
          garde_name.forEach((item, index) =>
            this.homework_student.series[0].data.push({
              value: pie_arr[index],
              name: item,
            })
          );
          this.handleInithomework_student();
          // 折线图
          // this.homework.title.text = "";
          let graph = res.data.graph;
          let months = graph.months;
          let arr = graph.arr;
          let year = graph.year;
          this.homework.xAxis.data = [];
          this.homework.legend.data = [];
          this.homework.series = [];
          months.forEach((item, index) => {
            this.homework.xAxis.data.push(item + "月");
            if (year[index]) {
              this.homework.legend.data.push(year[index]);
              this.homework.series.push({
                name: year[index],
                type: "line",
                stack: "Total",
                data: arr[index],
                label: {
                  show: true,

                  color: this.homework.color[index],
                  formatter: function (params) {
                    return params.value + "%";
                  },
                },
              });
            }
          });
          this.handleInithomework();
        } else if (type == 2) {
          // 饼图
          this.homework_knowledge_student.title.text =
            "家庭作业完成率占比" + this.yearName;
          let pie_chart = res.data.pie_chart;
          let pie_arr = pie_chart.arr;
          let garde_name = pie_chart.garde_name;
          this.homework_knowledge_student.series[0].data = [];
          garde_name.forEach((item, index) =>
            this.homework_knowledge_student.series[0].data.push({
              value: pie_arr[index],
              name: item,
            })
          );

          this.handleInithomework_knowledge_student();

          // 折线图
          let graph = res.data.graph;
          let months = graph.months;
          let arr = graph.arr;
          let year = graph.year;
          this.homework_knowledge.xAxis.data = [];
          this.homework_knowledge.legend.data = [];
          this.homework_knowledge.series = [];
          months.forEach((item, index) => {
            this.homework_knowledge.xAxis.data.push(item + "月");
            if (year[index]) {
              this.homework_knowledge.legend.data.push(year[index]);
              this.homework_knowledge.series.push({
                name: year[index],
                type: "line",
                stack: "Total",
                data: arr[index],
                label: {
                  show: true,

                  color: this.homework_knowledge.color[index],
                  formatter: function (params) {
                    return params.value + "%";
                  },
                },
              });
            }
          });
          this.handleInithomework_knowledge();
        }
      });
    },
    async handleGetstudentCoreCompetenciesLessonInfo(type) {
      let data = {
        start_time: this.startTime,
        end_time: this.endTime,
        lesson_type: type,
      };
      await studentCoreCompetenciesLessonInfo(data).then((res) => {
        //1 体育课  2 大课间

        if (type == 1) {
          // 饼图
          this.one_hour_student.title.text = "体育课出勤率分布" + this.yearName;
          let pie_chart = res.data.pie_chart;
          let pie_arr = pie_chart.arr;
          let garde_name = pie_chart.garde_name;
          this.one_hour_student.series[0].data = [];
          garde_name.forEach((item, index) =>
            this.one_hour_student.series[0].data.push({
              value: pie_arr[index],
              name: item,
            })
          );
          this.handleInitone_hour_student();
          // 折线图
          // this.one_hour.title.text = "体育课出勤";
          let graph = res.data.graph;
          let months = graph.months;
          let arr = graph.arr;
          let year = graph.year;
          this.one_hour.xAxis.data = [];
          this.one_hour.legend.data = [];
          this.one_hour.series = [];
          console.log(months, "=months");
          console.log(graph, "=graph");
          console.log(arr, "=arr");

          months.forEach((item, index) => {
            console.log(year[index]);
            this.one_hour.xAxis.data.push(item + "月");

            if (year[index]) {
              this.one_hour.legend.data.push(year[index]);
              this.one_hour.series.push({
                name: year[index],
                type: "line",
                stack: "Total",
                data: arr[index],
                label: {
                  show: true,
                  color: this.one_hour.color[index],
                  formatter: function (params) {
                    return params.value + "%";
                  },
                },
              });
            }
          });
          // console.log(this.one_hour, "= this.one_hour");
          this.handleInitone_hour();
        } else if (type == 2) {
          // 饼图
          this.during_break_time_student.title.text =
            "大课间出勤率分布" + this.yearName;
          let pie_chart = res.data.pie_chart;
          let pie_arr = pie_chart.arr;
          let garde_name = pie_chart.garde_name;
          this.during_break_time_student.series[0].data = [];
          garde_name.forEach((item, index) =>
            this.during_break_time_student.series[0].data.push({
              value: pie_arr[index],
              name: item,
            })
          );

          this.handleInitduring_break_time_student();
          // 折线图
          let graph = res.data.graph;
          let months = graph.months;
          let arr = graph.arr;
          let year = graph.year;
          this.during_break_time.xAxis.data = [];
          this.during_break_time.legend.data = [];
          this.during_break_time.series = [];
          months.forEach((item, index) => {
            this.during_break_time.xAxis.data.push(item + "月");
            if (year[index]) {
              this.during_break_time.legend.data.push(year[index]);
              this.during_break_time.series.push({
                name: year[index],
                type: "line",
                stack: "Total",
                data: arr[index],
                label: {
                  show: true,

                  color: this.during_break_time.color[index],
                  formatter: function (params) {
                    return params.value + "%";
                  },
                },
              });
            }
          });
          this.handleInitduring_break_time();
        }
      });
    },
    async handleGetstudentCoreCompetenciesOverview() {
      let data = {
        start_time: this.startTime,
        end_time: this.endTime,
      };
      await studentCoreCompetenciesOverview(data).then((res) => {
        this.evaluation.series[0].data = [];
        let info = res.data.info;
        info.forEach((item, index) => {
          this.evaluation.series[0].data.push({
            value: item,
            name: index,

            label: {
              normal: {
                show: true,
                color: this.evaluation.color[index],
                formatter: function (params) {
                  return params.value + "%";
                },
              },
            },
            areaStyle: {
              color: new echarts.graphic.RadialGradient(0.1, 0.6, 1, [
                {
                  color: this.evaluation.color[index],
                  offset: 0,
                },
                {
                  color: this.evaluation.color[index],
                  offset: 1,
                },
              ]),
            },
          });
        });
        this.evaluationInfo = {
          score: res.data.score,
          type: res.data.type,
        };
        this.handleInitevaluation();
      });
    },
    async handleGetstudentCoreCompetenciesStudentSituation() {
      let data = {
        start_time: this.startTime,
        end_time: this.endTime,
      };
      await studentCoreCompetenciesStudentSituation(data).then((res) => {
        this.studentInfo = res.data.school_info;

        //男女比例
        let student_rate = res.data.student_rate;
        let sex_ratioData = [
          {
            value: student_rate.boy_rate ? student_rate.boy_rate : 0,
            name: "男",
          },
          {
            value: student_rate.girl_rate ? student_rate.girl_rate : 0,
            name: "女",
          },
        ];
        this.sex_ratio.legend.formatter = function (name) {
          // 在这里编写自定义的格式化逻辑
          let value;
          for (let i = 0; i < sex_ratioData.length; i++) {
            // value = grade_ratioData[i].value;
            if (name == sex_ratioData[i].name) {
              return name + sex_ratioData[i].value + "%";
            }
          }
        };
        this.sex_ratio.series[0].data = sex_ratioData;
        this.handleInitsexRatio();

        // 课后一小时
        let implementation_rate = res.data.implementation_rate;
        let one_hour_after_classData = [
          {
            value: implementation_rate.join ? implementation_rate.join : 0,
            name: "参与",
          },
          {
            value: implementation_rate.unjoin ? implementation_rate.unjoin : 0,
            name: "未参与",
          },
        ];
        this.one_hour_after_class.legend.formatter = function (name) {
          let value;
          for (let i = 0; i < one_hour_after_classData.length; i++) {
            // value = grade_ratioData[i].value;
            // console.log(i, name);
            if (name == one_hour_after_classData[i].name) {
              return name + one_hour_after_classData[i].value + "%";
            }
          }
        };
        this.one_hour_after_class.series[0].data = one_hour_after_classData;
        this.handleInitone_hour_after_class();

        // 社会服务机构报名人数
        let number_service = res.data.apply_num;
        this.number_service.series[0].data = number_service;
        this.handleInitnumber_service();

        // 校队人数
        let number_of_school_teams = res.data.school_team_num;
        this.number_of_school_teams.series[0].data = number_of_school_teams;
        this.handleInitnumber_of_school_teams();

        // 比赛获奖人数
        let number_of_winners = res.data.award_winning_num;
        this.number_of_winners.series[0].data = [];
        let number_of_winnerslist = [];
        number_of_winners.forEach((item, index) => {
          this.number_of_winners.series[0].data.push({
            value: item.count,
            name: item.match_name,
          });
          number_of_winnerslist.push({
            value: item.count,
            name: item.match_name,
          });
        });
        this.number_of_winners.legend.formatter = function (name) {
          // 在这里编写自定义的格式化逻辑
          let value;
          for (let i = 0; i < number_of_winnerslist.length; i++) {
            if (name == number_of_winnerslist[i].name) {
              return name + number_of_winnerslist[i].value + "人";
            }
          }
        };
        this.handleInitnumber_of_winners();
      });
    },
    async handleGetStudentInfoCoreCompetencies() {
      let data = {
        start_time: this.startTime,
        end_time: this.endTime,
      };
      await studentInfoCoreCompetencies(data).then((res) => {
        this.headerTop = res.data;
      });
    },
    handleChangeTime(val) {
      this.startTime = val.startTime;
      this.endTime = val.endTime;
      this.yearName = val.value;

      this.handleGetstudentCoreCompetenciesPhysicalHealth();
      this.handleGetStudentInfoCoreCompetencies();
      this.handleGetstudentCoreCompetenciesStudentSituation();
      this.handleGetstudentCoreCompetenciesOverview();
      //1 体育课  2 大课间
      this.handleGetstudentCoreCompetenciesLessonInfo(1);
      this.handleGetstudentCoreCompetenciesLessonInfo(2);

      //1 = 家庭作业（体育运动）， 2 = 家庭作业（知识素养）
      this.handleGetstudentCoreCompetenciesHomeworkInfo(1);
      this.handleGetstudentCoreCompetenciesHomeworkInfo(2);
    },
    handleGetstudentCoreCompetenciesPhysicalHealth() {
      let data = {
        start_time: this.startTime,
        end_time: this.endTime,
      };
      studentCoreCompetenciesPhysicalHealth(data).then((res) => {
        let val = res.data;
        let year = [];
        let excellent = val.tree_diagram.excellent;
        let pass = val.tree_diagram.pass;
        val.tree_diagram.year.forEach((item, index) => {
          year.push([
            item,
            excellent[index] ? excellent[index] : 0,
            pass[index] ? pass[index] : 0,
          ]);
        });
        this.health.dataset.source = [["product", "优秀率", "合格率"], ...year];
        this.handleInithealth();

        this.health_student.title.text = "体质健康双达标分布图" + this.yearName;

        let bar_chart = val.bar_chart;
        this.health_student.yAxis.data = bar_chart.grade;
        this.health_student.series[0].data = bar_chart.fail;
        this.health_student.series[1].data = bar_chart.pass;
        this.health_student.series[2].data = bar_chart.good;
        this.health_student.series[3].data = bar_chart.excellent;
        this.handleInithealthStudent();
      });
    },
    handleTo(url) {
      this.$router.push(url);
    },
    handleInitclub_student() {
      let chartDom = this.$refs.club_student;
      let myChart = echarts.init(chartDom);
      myChart.setOption(this.club_student);
    },
    handleInitsports_art() {
      let chartDom = this.$refs.sports_art;
      let myChart = echarts.init(chartDom);
      myChart.setOption(this.sports_art);
    },
    handleInitsports_art_student() {
      let chartDom = this.$refs.sports_art_student;
      let myChart = echarts.init(chartDom);
      myChart.setOption(this.sports_art_student);
    },
    handleInithomework_knowledge_student() {
      let chartDom = this.$refs.homework_knowledge_student;
      let myChart = echarts.init(chartDom);
      myChart.setOption(this.homework_knowledge_student);
    },
    handleInithomework_knowledge() {
      let chartDom = this.$refs.homework_knowledge;
      let myChart = echarts.init(chartDom);
      myChart.setOption(this.homework_knowledge);
    },
    handleInithomework_student() {
      let chartDom = this.$refs.homework_student;
      let myChart = echarts.init(chartDom);
      myChart.setOption(this.homework_student);
    },
    handleInithomework() {
      let chartDom = this.$refs.homework;
      let myChart = echarts.init(chartDom);
      myChart.setOption(this.homework);
    },
    handleInitduring_break_time_student() {
      let chartDom = this.$refs.during_break_time_student;
      let myChart = echarts.init(chartDom);
      myChart.setOption(this.during_break_time_student);
    },
    handleInitduring_break_time() {
      let chartDom = this.$refs.during_break_time;
      let myChart = echarts.init(chartDom);
      myChart.setOption(this.during_break_time);
    },
    handleInitone_hour_student() {
      let chartDom = this.$refs.one_hour_student;
      let myChart = echarts.init(chartDom);
      myChart.setOption(this.one_hour_student);
    },
    handleInitone_hour() {
      let chartDom = this.$refs.one_hour;
      let myChart = echarts.init(chartDom);
      myChart.setOption(this.one_hour);
    },
    handleInithealth() {
      let chartDom = this.$refs.health;
      let myChart = echarts.init(chartDom);
      myChart.setOption(this.health);
    },
    handleInithealthStudent() {
      let chartDom = this.$refs.health_student;
      let myChart = echarts.init(chartDom);
      myChart.setOption(this.health_student);
    },
    handleInitevaluation() {
      let chartDom = this.$refs.evaluation;
      let myChart = echarts.init(chartDom);
      myChart.setOption(this.evaluation);
    },
    handleInitnumber_of_winners() {
      let chartDom = this.$refs.number_of_winners;
      let myChart = echarts.init(chartDom);
      myChart.setOption(this.number_of_winners);
    },
    handleInitnumber_of_school_teams() {
      let chartDom = this.$refs.number_of_school_teams;
      let myChart = echarts.init(chartDom);
      myChart.setOption(this.number_of_school_teams);
    },
    handleInitnumber_service() {
      let chartDom = this.$refs.number_service;
      let myChart = echarts.init(chartDom);
      myChart.setOption(this.number_service);
    },
    handleInitone_hour_after_class() {
      let chartDom = this.$refs.one_hour_after_class;
      let myChart = echarts.init(chartDom);
      myChart.setOption(this.one_hour_after_class);
    },
    handleInitsexRatio() {
      let chartDom = this.$refs.sex_ratio;
      let myChart = echarts.init(chartDom);
      myChart.setOption(this.sex_ratio);
    },
  },
};
</script>
<style lang="less" scoped>
body {
  overflow: hidden;
}
.container {
  height: 110%;
  overflow: scroll;
  padding: 20px;
  box-sizing: border-box;
}
/deep/.cell {
  color: #202020 !important;
}
.header {
  margin-top: 22px;
  .posi {
    position: relative;

    .detail {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 35px;
      background-color: rgba(21, 145, 165, 1);
      color: rgba(16, 16, 16, 1);
      font-size: 14px;
      text-align: center;
      border-radius: 0 0 10px 10px;
      color: rgba(255, 255, 255, 1);
      font-size: 16px;
      z-index: 999;
      .img_det {
        width: 20px;
        height: 20px;
        margin-left: 12px;
      }
    }
  }
  .header_box1 {
    cursor: pointer;
    box-sizing: border-box;
    height: 138px;
    width: 24.5%;
    border-radius: 10px;
    background-color: rgba(23, 162, 184, 1);
    padding: 0 0 0 30px;
    box-shadow: 0px 2px 6px 0px rgba(222, 222, 222, 1);
    position: relative;
    .num {
      color: rgba(255, 255, 255, 1);
      font-size: 35px;
      margin-bottom: 6px;
      padding-top: 23px;
      box-sizing: border-box;
      span {
        font-size: 18px;
      }
    }
    .num_box1 {
    }
    .num_title {
      color: rgba(255, 255, 255, 1);
      font-size: 18px;
      box-sizing: border-box;
      padding-bottom: 39px;
      // position: absolute;
      // bottom: -5px;
    }
    .header_img {
      width: 125px;
      height: 125px;
      position: absolute;
      right: 0;
    }
  }
  .header_box4 {
    background-color: #dc3545;
    .detail {
      background-color: rgba(198, 48, 62, 1);
    }
  }
  .header_box3 {
    background-color: #ffc107;
    .detail {
      background-color: rgba(229, 173, 6, 1);
    }
  }
  .header_box2 {
    background-color: #28a745;
    .detail {
      background-color: rgba(36, 150, 62, 1);
    }
  }
  .header_box5 {
    background-color: #7f63f2;
    .detail {
      background-color: #6644f3;
    }
  }
}
.box1 {
  margin-top: 16px;
  .box1_left {
    width: 70%;
    height: 350px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 6px 0px rgba(222, 222, 222, 1);
    box-sizing: border-box;
    .echart_box {
      width: 19%;
      height: 300px;
    }
  }
  .box1_title {
    color: rgba(16, 16, 16, 1);
    font-size: 20px;
    width: 100%;
    height: 52px;
    line-height: 52px;
    padding: 0 22px;
    border-bottom: 1px solid #e4e4e4;
    box-sizing: border-box;
    .more {
      color: rgba(134, 134, 134, 1);
    }
    /deep/.more:hover {
      color: #226bff; /* 鼠标放上去的颜色 */
      cursor: pointer;
    }
    a {
      // text-decoration: underline;
      color: rgba(16, 16, 16, 1);
    }
    a:hover {
      color: #226bff; /* 鼠标放上去的颜色 */
      cursor: pointer;
    }
  }
  .box1_right {
    width: 29%;
    height: 350px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 1);

    box-shadow: 0px 2px 6px 0px rgba(222, 222, 222, 1);
    box-sizing: border-box;
    position: relative;
    .evaluation {
      height: 257px;
      color: rgba(16, 16, 16, 1);
      font-size: 18px;
      text-align: center;
      padding-top: 20px;
      box-sizing: border-box;
    }
    .evaluValue {
      position: absolute;
      top: 68px;
      left: 20px;
      width: 78px;
      height: 79px;
      border-radius: 10px;
      background-color: rgba(34, 107, 255, 1);
      color: rgba(255, 255, 255, 1);
      font-size: 24px;
      text-align: center;
      span {
        font-size: 20px;
      }
    }
  }
}
.box2 {
  width: 100%;
  // height: 400px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 6px 0px rgba(130, 130, 130, 0.4);
  margin-top: 16px;
  .box2_title {
    color: rgba(16, 16, 16, 1);
    font-size: 20px;
    width: 100%;
    height: 52px;
    line-height: 52px;
    padding: 0 22px;
    border-bottom: 1px solid #e4e4e4;
    box-sizing: border-box;
  }
  .attendance {
    width: 70%;
    height: 332px;
  }
  .attendanceStudent {
    width: 29%;
    height: 332px;
  }
}
.box3 {
  width: 100%;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 6px 0px rgba(130, 130, 130, 0.4);
  margin-top: 16px;

  .box3_list {
    padding: 0 22px 22px 22px;
    box-sizing: border-box;
  }
  .box2_title {
    color: rgba(16, 16, 16, 1);
    font-size: 20px;
    width: 100%;
    height: 52px;
    line-height: 52px;
    padding: 0 22px;
    border-bottom: 1px solid #e4e4e4;
    box-sizing: border-box;
  }
  .box3_item {
    margin-top: 16px;
    width: 100%;
    height: 437px;
    border: 1px solid rgba(228, 228, 228, 1);
    box-sizing: border-box;
    padding-bottom: 22px;
    .attendance {
      width: 70%;
      height: 417px;
    }
    .attendanceStudent {
      width: 29%;
      height: 417px;
    }
  }
}
.box3_2,
.box2 {
  /deep/ .box3_item {
    border: 0;
    height: 347px;
  }
  /deep/ .attendance2 {
    width: 70%;
    height: 347px;
  }
  /deep/ .attendanceStudent2 {
    width: 29%;
    height: 347px;
  }
  .attendance3 {
    width: 70%;
    padding-bottom: 10px;
  }
}
.box2 {
  .attendance2 {
    padding: 0 20px;
    box-sizing: border-box;
  }
  .img_a_im {
    width: 24px;
    height: 31px;
    margin: 0 auto;
  }
  .elpag {
    margin-top: 10px;
  }
}
.box4 {
  margin-top: 16px;
  align-items: stretch; //
  .box4_left {
    width: 70%;
    // height: 400px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 6px 0px rgba(130, 130, 130, 0.4);

    .attendance2 {
      padding: 0 20px;
      box-sizing: border-box;
    }
    .img_a_im {
      width: 24px;
      height: 31px;
      margin: 0 auto;
    }
    .elpag {
      margin-top: 10px;
    }
    .attendance3 {
      // width: 70%;
      padding-bottom: 10px;
    }
  }
  .box4_right {
    // flex: 1;
    width: 29%;
    // height: 400px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 6px 0px rgba(130, 130, 130, 0.4);
    .list {
      flex-wrap: wrap;
      padding-bottom: 10px;
      box-sizing: border-box;
    }
    .list_item {
      width: 33%;
      text-align: center;
      margin-top: 25px;
      .ball {
        width: 69px;
        height: 69px;
        // line-height: 69px;
        border-radius: 50%;
        margin: 0 auto;
        background-color: rgba(127, 99, 242, 1);
        img {
          width: 35px;
          height: 35px;
        }
      }
      .ball2 {
        background-color: #ffc107;
      }
      .ball3 {
        background-color: #28a745;
      }
      .ball4 {
        background-color: #68bbc4 100%;
      }
      .ball5 {
        background-color: #6dc8e1;
      }
      .ball6 {
        background-color: #67b3e8;
      }
      .num {
        margin-top: 9px;
        margin-bottom: 3px;
        color: rgba(16, 16, 16, 1);
        font-size: 24px;
      }
      .ball_title {
        color: rgba(16, 16, 16, 1);
        font-size: 16px;
      }
    }
  }
  .box2_title {
    color: rgba(16, 16, 16, 1);
    font-size: 20px;
    width: 100%;
    height: 52px;
    line-height: 52px;
    padding: 0 22px;
    border-bottom: 1px solid #e4e4e4;
    box-sizing: border-box;
  }
}
.more {
  color: rgba(134, 134, 134, 1);
}
/deep/.more:hover {
  color: #226bff; /* 鼠标放上去的颜色 */
  cursor: pointer;
}
.num_box1 {
  width: 100%;
  padding-right: 10px;
  box-sizing: border-box;
  // position: absolute;
  z-index: 999 !important;
  .num {
    z-index: 999 !important;
  }
  .num2 {
    margin-left: 10px;
  }
}
.header_img1 {
  position: absolute;
  right: 0;
  z-index: 10 !important;
}
.attendance3 {
  /deep/.el-table--enable-row-hover
    .el-table__body
    tr:hover
    > td.el-table__cell {
    .cell {
      color: #226bff !important;
    }
  }
}
.box2,
.box4 {
  /deep/.el-table .el-table__cell {
    padding: 10px 0;
  }
}
.num_box12 {
  position: relative;
  height: 100%;
  .num {
    line-height: 15px;
  }
  .num_title {
    position: absolute;
    bottom: -5px;
  }
}
.detail {
}

// /deep/ .el-popconfirm {
// .el-popconfirm__main {
//   margin-bottom: 10px !important;
//   /* text-align: center; */
//   justify-content: center !important;
// }

// }
</style>
